body {
    background-image: url("background.jpg");
    background-repeat: no-repeat;
    font-family: 'Share Tech Mono', monospace;
}

a {
    color: whitesmoke;
    text-decoration: none;
    border-bottom: 1px dashed whitesmoke;
    align-self: center;
}

li {
    list-style: none;
    margin-bottom: 20px;
    margin-left: -20px;
}

.background {
    display: grid;
    grid-template-columns: 3px 1fr 3px;
    grid-template-rows: 3px 1fr 3px;
    color: whitesmoke;
}

@media (min-width: 600px) {
    .background {
        grid-template-columns: 20px minmax(auto, 650px) 20px;
        grid-template-rows: 20px 1fr 20px;
    }
}

.bio {
    grid-column-start: 2;
    grid-row-start: 2;
    background: rgba(0, 0, 0, 0.65);
    border-radius: 15px;
    padding: 15px;
}

.logo_image {
    width: 28px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.bio p {
    line-height: 2em;
}
